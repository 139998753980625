@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700);

$base_font_family: "Poppins ", sans-serif;

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700) ;
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


// @media only screen 
// and (min-device-width : 320px) 
// and (max-device-width : 480px) {
// /* Styles */
// }

// Smiley Animation

.smiley {
  width: 8em;
  height: 8em;
}
.smiley__eye1,
.smiley__eye2,
.smiley__mouth1,
.smiley__mouth2 {
  animation: eye1 3s ease-in-out infinite;
}
.smiley__eye1,
.smiley__eye2 {
  transform-origin: 64px 64px;
}
.smiley__eye2 {
  animation-name: eye2;
}
.smiley__mouth1 {
  animation-name: mouth1;
}
.smiley__mouth2 {
  animation-name: mouth2;
  visibility: hidden;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 90%, 10%);
    --fg: hsl(var(--hue), 90%, 90%);
  }
}

/* Animations */
@keyframes eye1 {
  from {
    transform: rotate(-260deg) translate(0, -56px);
  }
  50%,
  60% {
    animation-timing-function: cubic-bezier(0.17, 0, 0.58, 1);
    transform: rotate(-40deg) translate(0, -56px) scale(1);
  }
  to {
    transform: rotate(225deg) translate(0, -56px) scale(0.35);
  }
}
@keyframes eye2 {
  from {
    transform: rotate(-260deg) translate(0, -56px);
  }
  50% {
    transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1);
  }
  52.5% {
    transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1, 0);
  }
  55%,
  70% {
    animation-timing-function: cubic-bezier(0, 0, 0.28, 1);
    transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1);
  }
  to {
    transform: rotate(150deg) translate(0, -56px) scale(0.4);
  }
}
@keyframes eyeBlink {
  from,
  25%,
  75%,
  to {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0);
  }
}
@keyframes mouth1 {
  from {
    animation-timing-function: ease-in;
    stroke-dasharray: 0 351.86;
    stroke-dashoffset: 0;
  }
  25% {
    animation-timing-function: ease-out;
    stroke-dasharray: 175.93 351.86;
    stroke-dashoffset: 0;
  }
  50% {
    animation-timing-function: steps(1, start);
    stroke-dasharray: 175.93 351.86;
    stroke-dashoffset: -175.93;
    visibility: visible;
  }
  75%,
  to {
    visibility: hidden;
  }
}
@keyframes mouth2 {
  from {
    animation-timing-function: steps(1, end);
    visibility: hidden;
  }
  50% {
    animation-timing-function: ease-in-out;
    visibility: visible;
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -351.86;
  }
}




// cometchat code

// * {
//   box-sizing: border-box;
// }

// html {
//   text-size-adjust:100%;
//   font-size:10px;
//   height: 100%;
//   width: 100%;
// }

body{
  margin:0;
  padding:0;
  font-family: "Poppins";
  font-size: 1.4rem; 
  overflow-x: hidden;
  // overflow-y: hidden;
  background-color: #fff;
  color: #141414;
  box-sizing:border-box;
  height: 100%;
  width: 100%;
}

// a { 
//   background:0 0;
//   text-decoration:none;
//   background-color:transparent;
//   color:inherit;
// }

// a,
// a:visited,
// a:active,
// a:hover {
//   color: white;
//   text-decoration: none;
// }

// img {
//   border:0;
//   border-style:none;
//   max-width:100%;
//   display:inherit;
// }

// button,
// input,
// select,
// textarea {
//   font-family:inherit;
//   font-size:100%;
//   margin:0;
//   font:inherit;
// }

// button,input {
//   line-height:normal;
//   overflow:visible;
// }

// button,
// html input[type="button"],
// input[type="reset"],
// input[type="submit"],
// html [type="button"],
// [type="reset"],
// [type="submit"] {
//   -webkit-appearance:button;
//   cursor:pointer;
//   outline:0;
//   border:0;
//   // background-color:transparent;
// }

// h1,h2,h3,h4,h5,h6 {
//   font-family: $base_font_family;
//   font-stretch:normal;
// }

// h4 {
//   font-size: 2.2rem;
//   line-height:27px;
// }

// .clearfix::after,
// .clearfix::before {
//   content:" ";
//   display:table;
// }

// .clearfix,
// .clearfix::after {
//   clear:both;
// }